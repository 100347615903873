<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import { postAction, getAction } from '@/command/netTool'
import moment from 'moment'
export default {
  name: 'secondsKill',
  data() {
    return {
      ...api.command.getState(),
      storeData: [],
      dataId: null,
      specificationsDataList: [],
    }
  },
  mounted() {
    this.handleGetInit()
    this.storeSearch()
  },
  methods: {
    handleGetInit() {
      api.command.getList.call(this, {
        url: '/market/farmProductSpike/page',
        current: 1,
      })
    },
    handlegetPriorc(productId = null) {
      getAction('/api/commodity/farmNativeProductStandards/list?productId=' + productId).then((result) => {
        if (result.code == 200) {
          this.specificationsDataList = result.data.map((e) => {
            return {
              ...e,
              name: e.standardsName,
              value: e.id,
            }
          })
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    getHeader() {
      return [
        {
          name: '秒杀产品',
          key: 'productName',
          type: 'input',
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'shopName',
        },
        {
          name: 'ID',
          type: 'input',
          key: 'id',
        },
        {
          name: '开始结束时间',
          type: 'rangePicker',
          placeholder: '请选择新闻发布日期',
          keys: ['beginDate', 'endDate'],
        },
      ]
    },
    getColumns() {
      return [
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'productName',
          title: '秒杀产品',
          filter: true,
          align: 'left',
          sorter: (a, b) => a.productName.length - b.productName.length,
        },
        {
          dataIndex: 'shopName',
          title: '店铺名称',
          align: 'left',
        },
        {
          dataIndex: 'beginTime',
          title: '开始时间',
          align: 'left',
          sorter: (a, b) => moment(a.beginTime).unix() - moment(b.beginTime).unix(),
        },
        {
          dataIndex: 'endTime',
          title: '结束时间',
          align: 'left',
          sorter: (a, b) => moment(a.endTime).unix() - moment(b.endTime).unix(),
        },
        {
          dataIndex: 'totalSale',
          title: '秒杀量',
          align: 'left',
          customRender: function (text, records) {
            const dataTotalSale = records.totalSale ? records.totalSale : 0
            return (
              <div>{`${dataTotalSale} （${((Number(dataTotalSale) / records.originInventory) * 100).toFixed(
                0
              )}%）`}</div>
            )
          },
          onExport: (text, records) => {
            const dataTotalSale = records.totalSale ? records.totalSale : 0
            return `${dataTotalSale} （${((Number(dataTotalSale) / Number(records.originInventory)) * 100).toFixed(
              0
            )}）%`
          },
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.editPost.call(this, {
                    url: `/market/farmProductSpike/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`,
                  })
                },
              },
              {
                name: '编辑',
                onClick: () => {
                  this.onRowSelect(records)
                },
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.delPost.call(this, {
                    url: `/market/farmProductSpike/delete?id=${records.id}`,
                  })
                },
              },
            ]
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.onRowSelect()
          },
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.delPostArr.call(this, {
                  url: '/market/farmProductSpike/deleteBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.editPost.call(this, {
                  url: '/market/farmProductSpike/upBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.editPost.call(this, {
                  url: '/market/farmProductSpike/downBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
    async storeSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmNativeProduct/list?canBuy=1&name=${value}`,
      })
      this.storeData = searchResult.map((el) => {
        return {
          ...el,
          value: el.id,
        }
      })
    },
    onProductMenuClick(data) {
      this.handlegetPriorc(data.id)
    },
    getDrawerForm(source, formData) {
      if (formData.productStandardsId) {
        formData.salePrice = (
          this.specificationsDataList.find((e) => e.id == formData.productStandardsId) || { salePrice: '' }
        ).salePrice
      }

      const form = [
        {
          title: '产品（单规格秒杀）',
          form: [
            {
              name: '秒杀产品',
              type: 'dropDownInput',
              key: 'productName',
              onInputSearch: this.storeSearch,
              onMenuClick: this.onProductMenuClick,
              valueKey: 'productId',
              inputProps: {
                addonBefore: <a-icon type="search" />,
              },
              rules: [
                {
                  required: true,
                },
              ],
            },
            {
              name: '产品规格',
              key: 'productStandardsId',
              type: 'radioButton',
              width: '100%',
              typeData: this.specificationsDataList,
              rules: [
                {
                  required: true,
                },
              ],
            },
          ],
        },
        {
          title: '价格',
          form: [
            {
              name: '产品原价',
              type: 'text',
              key: 'salePrice',
            },
            {
              name: '秒杀价格',
              type: 'input',
              key: 'spikePrice',
              props: {
                placeholder: '请输入秒杀价格',
                addonAfter: '元',
                min: 0,
              }, 
              rules: [
                {
                  required: true,
                },
                {
                  ruleType:'numdot',
                  message:'格式不正确'
                },
              ],
            },
          ],
        },
        {
          title: '时间',
          form: [
            {
              name: '开始时间',
              type: 'datePicker',
              key: 'beginTime',
              cols: 24,
              props: {
                showTime: true,
                format: 'YYYY-MM-DD HH:mm',
                valueFormat: 'YYYY-MM-DD HH:mm:ss',
                allowClear: false,
              },
              rules: [
                {
                  required: true,
                  type: 'date',
                  message: '请选择开始时间',
                },
              ],
            },
            {
              name: '结束时间',
              type: 'datePicker',
              key: 'endTime',
              cols: 24,
              props: {
                showTime: true,
                format: 'YYYY-MM-DD HH:mm',
                valueFormat: 'YYYY-MM-DD HH:mm:ss',
                allowClear: false,
              },
              rules: [
                {
                  required: true,
                  type: 'date',
                  message: '请选择结束时间',
                },
              ],
            },
          ],
        },
        {
          title: '其他',
          form: [
            {
              name: '秒杀库存',
              type: 'input',
              key: 'inventory',
              props: {
                placeholder: '请输入秒杀库存',
                addonAfter: '件',
                min: 1,
              },
              rules: [
                {
                  required: true,
                },
                {
                  ruleType:'integer',
                  message:'格式不正确'
                },
              ],
            },
            {
              name: '优先排序',
              type: 'inputNumber',
              key: 'sort',
              props: {
                placeholder: '请输入0-100的数字',
                min: 0,
                max: 100,
              },
            },
          ],
        },
      ]
      return form
    },
    onRowSelect(records = {}) {
      if (records.id) {
        this.dataId = records.id
        getAction('/api/market/farmProductSpike/get?id=' + records.id).then((result) => {
          if (result.code == 200) {
            this.handleStatePopup({...result.data,inventory:result.data.inventory +'',spikePrice:result.data.spikePrice + ''})
          } else {
            this.$message.error(result.msg)
          }
        })
      } else {
        this.dataId = null
        this.handleStatePopup()
      }
    },
    handleStatePopup(
      source = {
        sort: '',
        inventory: '',
        spikePrice: '',
      }
    ) {
      this.handlegetPriorc(source.productId)
      let that = this
      apiTool.showDrawer({
        title: '秒杀设置',
        width: '720px',
        view: DrawerForm,
        typeData() {
          return {
            productName: that.storeData,
          }
        },
        viewProps: {
          data: (formData) => that.getDrawerForm(source, formData),
          form: source,
          formProps: {
            layout: 'horizontal',
            cols: 24,
            labelCol: { span: 3 },
            wrapperCol: { span: 21 },
          },
        },
        success: ({ data, setHidden }) => {
          if (
            !(
              data.productName &&
              data.productStandardsId &&
              data.spikePrice &&
              data.beginTime &&
              data.endTime &&
              data.inventory
            )
          ) {
            that.$message.warning('请完成必填项！')
            return false
          }
          if (moment(data.beginTime) > moment(data.endTime)) {
            that.$message.warning('结束时间应大于开始时间！')
            return false
          }

          if (data.productId) {
            data.shopName = (
              that.storeData.find((e) => e.id == data.productId) || {
                mainBodyName: '',
              }
            ).mainBodyName
          }

          api.command[!that.dataId ? 'create' : 'editPost']
            .call(that, {
              url: `/market/farmProductSpike/${!that.dataId ? 'add' : 'update'}`,
              params: {
                ...data,
                id: that.dataId,
                beginTime: moment(data.beginTime).format('YYYY-MM-DD HH:mm:ss'),
                endTime: moment(data.endTime).format('YYYY-MM-DD HH:mm:ss'),
              },
            })
            .then(setHidden)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
